<template>
  <b-card>
    <g-form @submit="save">

      <b-row>
        <!-- transaction date  -->
        <b-col
          v-if="!currentBranch.setDefaultDate"
          md="4"
        >
          <g-field
            :value="getDate(selectedItem.transactionDate)"
            label-text="date"
            disabled
            name="transactionDate"
          />
        </b-col>

        <b-col
          v-else
          md="4"
        >
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="transactionDate"
          />
        </b-col>

        <!-- transaction time  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              label-text="transactionTime"
              name="transactionTime"
              readonly
              :value.sync="selectedItem.transactionTime"
            />
          </b-form-group>
        </b-col>

        <!-- group type selection -->
        <b-col
          class="gb-package"
          md="4"
        >
          <label> {{ $t("groupType") }} </label>
          <b-button-group>
            <b-button
              :class="['gb-btn-child', {btnActive: selectedGroupName === 'stage'}]"
              variant="flat-warning"
              @click="groupSelection('stage')"
            >
              {{ $t("stage") }}
            </b-button>

            <b-button
              :class="['gb-btn-child', {btnActive: selectedGroupName === 'row'}]"
              variant="flat-warning"
              @click="groupSelection('row')"
            >
              {{ $t("row") }}
            </b-button>

            <b-button
              :class="['gb-btn-child', {btnActive: selectedGroupName === 'class'}]"
              variant="flat-warning"
              @click="groupSelection('class')"
            >
              {{ $t("class") }}
            </b-button>
          </b-button-group>
        </b-col>

        <!-- group name  -->
        <b-col
          v-if="selectedGroupName !== ''"
          md="4"
        >
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              name="selectedGroup"
              rules="required"
              :label-text="selectedGroupName"
              :options="selectedGroup"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.groupValueId"
            />
          </b-form-group>
        </b-col>

        <!-- discount type -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="discountType"
              name="discountType"
              rules="required"
              :options="discounts"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.discountId"
              @change="(v) => {
                customizeBasedOnType(v)
              }"
            />
          </b-form-group>
        </b-col>

        <!-- service Related To Discount -->
        <b-col
          v-if="helper.isPercentage"
          md="4"
        >
          <b-form-group>
            <g-field
              label-text="serviceRelatedToDiscount"
              name="serviceName"
              readonly
              :value.sync="helper.selectedDiscount.serviceArabicName"
            />
          </b-form-group>
        </b-col>

        <!-- service value -->
        <b-col
          v-if="helper.isPercentage"
          md="4"
        >
          <b-form-group>
            <g-field
              label-text="serviceValue"
              name="serviceValue"
              readonly
              :value.sync="helper.selectedDiscount.serviceValue"
            />
          </b-form-group>
        </b-col>

        <!-- discount percentage -->
        <b-col
          v-if="helper.isPercentage"
          md="4"
        >
          <b-form-group>
            <g-field
              label-text="discountPercentage"
              name="discountPercentage"
              readonly
              :value.sync="helper.sPercentageValue"
            />
          </b-form-group>
        </b-col>

        <!-- discount value  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              label-text="discountValue"
              type="number"
              rules="required"
              :value.sync="selectedItem.value"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- notes -->
      <b-row class="mb-1">
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- operations -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            variant="relief-primary"
            data-action-type="apply"
            @click="apply"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            {{ $t("applyDiscountOnGroup") }}
          </b-button>
        </b-col>
      </b-row>

    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';

export default {
  mixins: [
    reportMixin,
  ],
  props: ['id'],
  data() {
    return {
      selectedItem: {
        groupValueId: 0,
      },

      helper: {
        selectedDiscount: {},
        sPercentageValue: '',
        isPercentage: false
      },

      services: [],
      invoices: [],
      discounts: [],
      stages: [],
      rows: [],
      classRooms: [],
      selectedGroup: [],
      selectedGroupName: ''
    };
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.loadObj();
    if (this.id > 0) {
      this.getData();
    }

    this.selectedItem.transactionDate = this.today;
    this.selectedItem.transactionTime = this.getTime();
  },

  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    groupSelection(groupName) {
      this.selectedGroupName = groupName;

      switch (this.selectedGroupName) {
        case 'stage':
          this.selectedGroup = this.stages;
          this.selectedItem.groupId = 1;
          break;

        case 'row':
          this.selectedGroup = this.rows;
          this.selectedItem.groupId = 2;
          break;

        case 'class':
          this.selectedGroup = this.classRooms;
          this.selectedItem.groupId = 3;
          break;

        default:
          this.selectedGroup = []
          this.selectedItem.groupId = 0
          break;
      }

      if (this.selectedGroup.length > 0) {
        this.selectedItem.groupValueId = this.selectedGroup[0].id;
      }
    },

    loadObj() {
      this.getStages();
      this.getRows();
      this.getClassRoms();
      this.getDiscounts();
    },

    customizeBasedOnType(discount) {
      this.helper.selectedDiscount = discount;
      this.helper.isPercentage = discount.valueType === 'percentage'
      this.helper.sPercentageValue = `${discount.value} %`

      this.selectedItem.value = this.helper.isPercentage
                              ? discount.serviceValue * (discount.value / 100)
                              : discount.value;
    },

    resetChoices() {
      this.selectedItem.discountId = null;
      this.selectedItem.value = 0;
    },

    getData() {
      this.get({
        url: 'StudentDiscounts',
        id: this.$route.params.id,
      }).then((data) => {
        this.selectedItem = data;
      })
    },

    getStages() {
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
    },

    getRows() {
      this.get({ url: 'Rows' }).then((data) => {
        this.rows = data;
      });
    },

    getClassRoms() {
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classRooms = data;
      });
    },

    getDiscounts() {
      this.get({ url: 'Discounts' }).then((data) => {
        this.discounts = data;
        this.discounts.forEach(discount => {
          if (discount.isServiceTaxable && discount.isServicePriceNotIncludeTax) {
            discount.serviceValue += discount.serviceValue * 0.15;
          }
        });
      });
    },

    backToList() {
      this.$router.push({ name: 'studentDiscounts' });
    },

    validateApplyForGroup() {
      if (this.selectedGroupName === '') {
        this.doneAlert({ text: this.$t('youMustSelectGroup'), type: 'error' });
        return false;
      }
      if (this.selectedItem.discountId === undefined) {
        return false;
      }
      return true;
    },

    apply() {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      // if no group selected inform user with message then return
      if (!this.validateApplyForGroup()) return;

      // get needed data
      const groupName = this.selectedGroup.find((val) => val.id === this.selectedItem.groupValueId).arabicName;

      // if no student in group infrom user with message other wise apply it
      this.get({ url: `Students/getStudentsCountByGroupId?group=${this.selectedItem.groupId}&id=${this.selectedItem.groupValueId}` }).then((outCount) => {
        if (outCount === 0) {
          this.doneAlert({ text: `${this.$t('noStudentIn')} ${groupName}`, type: 'info' });
          return;
        }

        this.create({
          url: 'StudentDiscounts',
          data: this.selectedItem,
        }).then(() => {
          this.doneAlert({
            text: `${this.$t('discountsAppliedForStudentsSuccessfullyIn')} ${groupName}`
          });
          this.backToList();
        });
      });
    },
  },
};
</script>

<style>
.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 3px;
}

.gb-package label {
  font-size: 14px;
}
b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2)
}
</style>
